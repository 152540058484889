.modal-client {

  #client-form-content {
    margin-bottom: 30px;

    .content {
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      margin-bottom: 30px;

      input {
        border-color: $gold;
        height: 48px;
      }

      .form-label {
        font-family: Jost, sans-serif;
        font-size: 16px;
      }
    }
  }

  .modal-footer {
    .row {
      width: 100%;
    }

    .btn {
      text-transform: uppercase !important;
      color: white;
      width: 100%;
    }
  }
}