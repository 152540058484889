.menu {
  //background-image: linear-gradient(rgba(0, 0, 0, 0.5),
  //        rgba(0, 0, 0, 0.5)), url("../img/banner.jpg");
  //height: max-content;
  //padding: 50px;

  .react-parallax {
    padding: 0px !important;
  }

  .desktop-menu {
    &.fixed {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030;
      background-color: rgba(0,0,0,.5);
      padding-left: 15%;
      padding-right: 15%;
      max-width: none;

      .nav-item {
        //margin: auto;

        .nav-link {
          font-size: 28px;
        }
      }
    }
  }

  &.big {
    .header {
      height: 800px;
    }
  }

  &.small {
    .header {
      height: 480px;
    }
    .react-parallax {
      height: 700px !important;
      width: 100% !important;
    }
  }

  .header {
    .header-subtitle,
    .header-description {
      font-family: Jost, sans-serif;
      font-size: 18px;
      font-weight:400;
      max-width: 600px;
    }

    .header-title {
      font-family: Marcellus, serif;
      font-size: 80px;
      font-style: normal;
      font-weight: 400;
      max-width: 600px;
    }
  }

  .language-switch {
    z-index: 1031;
    right: 20px;
    top: 25px;
    color: white;

    &.isFixed {
      top: 10px;
    }

    .language-switch-btn {
      cursor: pointer;

      &.selected {
        border-bottom: 2px solid $primary;
      }
    }
  }
}

.react-parallax-bgimage {
  filter: brightness(0.8) !important;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .desktop-menu {
    .nav-item a {
      font-size: 30px;
      padding: 8px;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .desktop-menu {
    .nav-item a {
      font-size: 26px;
      padding: 5px;
    }
  }
}

@media screen and (max-width: 575px) {
  .header {
    //.header-subtitle,
    //.header-description {
    //  font-size: 18px;
    //}

    .header-title {
      font-size: 50px !important;
    }
  }
  .menu.big .header {
    height: 70vH;
  }
}

@media screen and (max-width: 992px) {
  .language-switch {
      background-color: #8080804f;
    padding: 10px;
  }
}

.menu-mobile.navbar-collapse {
  height: 100vH;
  width: 100vW;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  padding: 20px;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 0.7); /* Black w/opacity */

  .nav-pills {
    position: relative;
    top: 2%; /* 25% from the top */
    width: 100%; /* 100% width */
    text-align: center; /* Centered text/links */
    margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */

    .nav-item {
      margin-bottom: 20px;

      .nav-link:hover:not(.active) {
        color: $primary;
      }
    }
  }
}