.content-total,
.content-detail {
  .title-total {
    font-family: Jost, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
  }
  .total {
    float: right;
    font-family: Jost, sans-serif;
    font-size: 24px;
    font-weight: 400;
  }
  .title-sub-total {
    font-family: Jost, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding-left: 10px;
  }
  .sub-total {
    float: right;
    font-family: Jost, sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .title-detail {
    font-family: Marcellus, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
  }
  .detail {
    float: right;
    font-family: Jost, sans-serif;
    font-size: 24px;
    font-weight: 400;
  }
}