@font-face {
  font-family: "LaBelleAurore";
  src: url('../fonts/LaBelleAurore-Regular.ttf');
}

.aurore {
  font-family: 'LaBelleAurore', Arial, sans-serif;
  font-weight:normal;
  font-style:normal;
}

@font-face {
  font-family: "Jost";
  src: url('../fonts/Jost-Regular.ttf');
}

.jost {
  font-family: 'Jost', Arial, sans-serif;
  font-weight:normal;
  font-style:normal;
}

@font-face {
  font-family: "Marcellus";
  src: url('../fonts/Marcellus-Regular.ttf');
}

.marcellus {
  font-family: 'Marcellus', Arial, sans-serif;
  font-weight:normal;
  font-style:normal;
}