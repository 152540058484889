// Custom.scss
$primary: #0ea1ab;
$secondary: #b2dfdb;
$tertiary: #4db6ac;
$gold: #B99D75;

@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/slick-carousel/slick/slick';
@import '../../node_modules/slick-carousel/slick/slick-theme';

@import "design/fonts";
@import "design/menu";
@import "design/animations";
@import "design/footer";

@import "design/contact";

@import "design/home/essentials";
@import "design/home/preview";
@import "design/home/review";
@import "design/home/discover";
@import "design/home/accommodation";

@import "design/booking/room";
@import "design/booking/sidebar";

@import "design/booking/recap";
@import "design/booking/client";

@import "design/admin/dashboard";

.main-content {
  min-height: 800px;
}


.nav-item .nav-link {
  color: white;
  font-family: 'LaBelleAurore', Arial, sans-serif;
  font-size: 31px;

  &:not(.active):hover {
    color: var(--bs-nav-link-color);
  }

  &.booking {
    text-decoration: underline 2px var(--bs-nav-pills-link-active-bg);
  }
}

.navbar {
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: white;
  }
  .navbar-toggler {
    border: none !important;
    box-shadow: none !important;
  }
}

/**
 * IMAGES ZOOM AND OFFSET BORDER
 */
.image-zoom {
  overflow: hidden;

  img {
    transition: transform 0.5s;

    &:hover {
      -ms-transform: scale(1.1); /* IE 9 */
      -webkit-transform: scale(1.1); /* Safari 3-8 */
      transform: scale(1.1);
    }
  }

  .overlay-label {
    position: absolute;
    bottom: 30px;
    left: -20px;
    padding: 10px 40px;
    background: $gold;
    color: white;
    width: fit-content;
    max-width: calc(100% - 20px);
    transition: background 0.3s, color 0.3s;
    border-radius: 5px;
  }

  .offset-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid $gold;
    transform: translate(-10px, -10px);
    transition: all 0.3s;
    will-change: transform, width, height;
    box-sizing: border-box;
  }

  &:hover {
    //cursor: pointer;
    .overlay-label {
      background: $primary;
    }
    .offset-border {
      transform: translate(+10px, +10px);
      width: calc(100% - 20px);
      height: calc(100% - 20px);
    }
    img{
      -ms-transform: scale(1.1); /* IE 9 */
      -webkit-transform: scale(1.1); /* Safari 3-8 */
      transform: scale(1.1);
    }
  }
}

/**
 * Font style
 */

.title-2 {
  font-family: Marcellus, serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
}

.title-3 {
  font-family: LaBelleAurore, serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
}

.title-4 {
  font-family: Jost, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #53624e;
  text-transform: uppercase;
}

p {
  font-family: Jost, sans-serif;
  font-size: 18px;
  font-weight: 40;
}

.gold {
  color: $gold;
}

.bold {
  font-weight: 600;
}

/**
 * Calendar
 */
.calendar__calendar-container {

  .day-footer-cell {
    position: absolute;
    left: 0;
    right: 0;
    line-height: 12px;
    text-transform: capitalize;
    z-index: 3;
    bottom: 3px;
    font-size: 8px;
    color: #ffffff;
    box-sizing: border-box;

    &.booked {
      color: #969696;
    }
  }

  .calendar__month-container {
    svg {
      fill: $gold;
      width: 44px;
      height: 44px;
      & path {
        fill: $gold !important;
      }
    }
    .calendar__month-content {
      span {
        color: black !important;
        font-size: 1rem;
      }
    }
  }

  .calendar__weekCell {
    color: black !important;
  }

  .calendar__dayCell-selected,
  .calendar__dayCell-selectedStart,
  .calendar__dayCell-selectedEnd {
    background-color: $gold;
    border-color: $gold;
  }
}

/**
 * Form
 */
.number-btn {
  display: inline-block;
  border: solid 1px #B0B0B0;
  border-radius: 50%;
  background: white;
}
.main-content input {
  border-color: #B99D75;
  height: 48px;
  border-radius: 0;
}
.main-content textarea {
  border-color: #B99D75;
  border-radius: 0;
  height: 150px;
}

.transform-perspective-left {
  transform: scale(1) perspective(2000px) rotateY(11deg) rotateX(2deg) rotate(-2deg);
}

.transform-perspective-right {
  transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg);
}

/**
 * Slick
 */
.slick-slider {
  .slick-prev {
    left: -15px;
  }

  .slick-next {
    right: -5px;
  }

  .slick-prev:before, .slick-next:before {
    color: white;
  }

  .slick-arrow:before {
    font-size: 60px !important;
  }
}

/**
 * Tables
 */
.table-striped > tbody > tr:nth-of-type(odd) > * {
  //background-color: rgba($secondary, 0.05);
  background-color: #0dcaf024;
}

/**
 * Others
 */
.activities .card-img-overlay {
  height: fit-content;
}
.btn-outline-secondary.active,
.btn-secondary,
.btn-outline-secondary:hover {
  color: $primary;
}